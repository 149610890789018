import(/* webpackMode: "eager" */ "/home/runner/work/in2event-front-end/in2event-front-end/node_modules/.pnpm/next@14.2.15_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.44.1_babel-plugin_muq646v64cwhmqbwopniw46jjm/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/in2event-front-end/in2event-front-end/node_modules/.pnpm/next@14.2.15_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.44.1_babel-plugin_muq646v64cwhmqbwopniw46jjm/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/in2event-front-end/in2event-front-end/node_modules/.pnpm/next@14.2.15_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.44.1_babel-plugin_muq646v64cwhmqbwopniw46jjm/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/in2event-front-end/in2event-front-end/node_modules/.pnpm/next@14.2.15_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.44.1_babel-plugin_muq646v64cwhmqbwopniw46jjm/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/in2event-front-end/in2event-front-end/node_modules/.pnpm/next@14.2.15_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.44.1_babel-plugin_muq646v64cwhmqbwopniw46jjm/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/in2event-front-end/in2event-front-end/node_modules/.pnpm/next@14.2.15_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.44.1_babel-plugin_muq646v64cwhmqbwopniw46jjm/node_modules/next/dist/client/components/render-from-template-context.js");
